<template>
  <div id="app-sidebar-5" class="h-screen surface-section hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-2 border-right-1 surface-border w-10rem lg:w-6rem select-none">
    <div class="flex flex-column h-full">
      <div class="flex align-items-center justify-content-center flex-shrink-0" style="height:37px; background-color: #424242;">
        <img src="~/assets/images/logos/logo.png" alt="Image" style="height:100%;">
      </div>
      <div>
        <ul class="list-none p-0 m-0">
          <li
            v-for="(item, itemKey) in menuItems"
            :key="itemKey"
            :class="[
              item.visible ? '' : 'hidden',
              item.disabled ? 'disabled' : 'cursor-pointer',
              route.path.includes(item.page) && item.page !== '/' || item.page === '/' && route.path === '/' ? 'border-left-3 border-orange-400' : '',
              'sidebar-menu',
              item.children ? 'relative' : ''
            ]"
            @click="goTo(item, item.external ? item.external : false)"
          >
            <a
              v-ripple
              v-styleclass="item.children ? {
                selector: '@next',
                enterClass: 'hidden',
                leaveToClass: 'hidden',
                hideOnOutsideClick: true
              } : {}"
              :class="[
                item.disabled ? 'cursor-auto hover:surface-200' : 'hover:bg-orange-500 hover:text-white hover:border-left-3 border-orange-500',
                'flex flex-row lg:flex-column align-items-center p-2 lg:justify-content-center border-round text-surface-600 transition-duration-150 transition-colors p-ripple',
                route.path.includes(item.label.toLocaleLowerCase()) && item.page !== '/' ? 'border-left-3 text-orange-400' : 'text-400']"
            >
              <i :class="['pi mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-3xl ' + item.icon]" />
              <span class="font-medium inline text-base lg:text-xs lg:block no-underline">{{ item.label }}</span>
              <i v-if="item.children" class="pi pi-chevron-down ml-auto lg:hidden" />
            </a>
            <ul
              v-if="item.children"
              style="width: 33vw !important;"
              class="grid list-none surface-section pl-3 pr-0 py-0 lg:p-0 m-0 lg:ml-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out static border-round-right lg:absolute left-100 top-0 z-3 shadow-none lg:shadow-2 w-full lg:w-6rem"
            >
              <li
                v-for="(child, childKey) in item.children"
                :key="childKey"
                :class="[
                  child.visible ? 'cursor-pointer' : 'hidden',
                  route.path.includes(child.page) && item.page !== '/' || child.page === '/' && route.path === '/' ? 'border-left-3 border-orange-400' : '',
                  'sidebar-menu col-3'
                ]"
                @click="goTo(child)"
              >
                <a
                  v-ripple
                  :class="[
                    child.visible ? 'hover:bg-orange-500 hover:text-white' : 'hidden',
                    'flex flex-row lg:flex-column align-items-center p-3 lg:justify-content-center border-round text-surface-600 transition-duration-150 transition-colors p-ripple',
                    route.path.includes(child.page) && child.page !== '/' || child.page === '/' && route.path === '/' ? 'text-orange-400' : 'text-400']"
                >
                  <i :class="['pi mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-3xl ' + child.icon]" />
                  <span class="font-medium inline text-base lg:text-xs lg:block no-underline text-center">{{ child.label }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
const route = useRoute();
const config = useRuntimeConfig();

const usersStore = useUsersStore();
const { userHasPermission } = usersStore;

const showCommerceTab = computed(() => {
  return userHasPermission("CAN_ACCESS_ADMIN_SITE") && userHasPermission("CAN_ACCESS_ADMIN_CONTACT");
});

const showAdminTab = computed(() => {
  return userHasPermission("CAN_ACCESS_ADMIN_DOCUMENT_EXCLUDED") &&
  userHasPermission("CAN_ACCESS_ADMIN_INCOME_TYPE") &&
  userHasPermission("CAN_ACCESS_ADMIN_CREDIT_TYPE") &&
  userHasPermission("CAN_ACCESS_ADMIN_CHARGE_TYPE") &&
  userHasPermission("CAN_ACCESS_ADMIN_COMMISSION") &&
  userHasPermission("CAN_ACCESS_ADMIN_BANK_PRODUCT") &&
  userHasPermission("CAN_ACCESS_ADMIN_LEGAL_CAPACITY") &&
  userHasPermission("CAN_ACCESS_ADMIN_INSURANCE_TYPE") &&
  userHasPermission("CAN_ACCESS_ADMIN_CONTACT_POSITION") &&
  userHasPermission("CAN_ACCESS_ADMIN_CONTACT_EVENT_CONTACT_EVENT_TYPE") &&
  userHasPermission("CAN_ACCESS_ADMIN_CASH_REQUIREMENT_GOAL") &&
  userHasPermission("CAN_ACCESS_ADMIN_BANK") &&
  userHasPermission("CAN_ACCESS_ADMIN_BRAND") &&
  userHasPermission("CAN_ACCESS_ADMIN_POSITION");
});

const menuItems = computed(() => {
  return [
    {
      label: "Accueil",
      page: config.public.ARC_URL + "/liste-taches",
      icon: "pi-home",
      visible: true,
      external: true
    },
    {
      label: "Tâches",
      page: config.public.ARC_URL + "/liste-taches ",
      icon: "pi-list",
      visible: true,
      external: true
    },
    {
      label: "Dossiers",
      // page: "/opportunities",
      page: config.public.ARC_URL + "/recherche_dossiers.php",
      icon: "pi-folder",
      visible: true,
      external: true
    },
    {
      label: "Agenda",
      page: "/agenda",
      icon: "pi-calendar",
      disabled: true,
      visible: true
    },
    {
      label: "Commerce",
      page: null,
      icon: "pi-briefcase",
      visible: showCommerceTab.value,
      children: [
        {
          label: "Site",
          page: "/commerce/sites",
          icon: "pi-sitemap",
          visible: userHasPermission("CAN_ACCESS_ADMIN_SITE")
        },
        {
          label: "Contact",
          page: "/commerce/contacts",
          icon: "pi-user",
          visible: userHasPermission("CAN_ACCESS_ADMIN_CONTACT")
        }
      ]
    },
    {
      label: "Reporting",
      page: config.public.ARC_URL + "/mains_reports.php ",
      icon: "pi-chart-bar",
      visible: true,
      external: true
    },
    {
      label: "Admin",
      page: null,
      icon: "pi-cog",
      visible: showAdminTab.value,
      children: [
      // {
      //   label: "Admin",
      //   page: "/admin",
      //   icon: "pi-cog",
      //   disabled: true
      // },
        {
          label: "Documents exclus",
          page: "/admin/excluded_documents",
          icon: "pi-file-excel",
          visible: userHasPermission("CAN_ACCESS_ADMIN_DOCUMENT_EXCLUDED")
        },
        {
          label: "Types revenus",
          page: "/admin/income_types",
          icon: "pi-pound",
          visible: userHasPermission("CAN_ACCESS_ADMIN_INCOME_TYPE")
        },
        {
          label: "Types crédit",
          page: "/admin/credit_types",
          icon: "pi-folder-open",
          visible: userHasPermission("CAN_ACCESS_ADMIN_CREDIT_TYPE")
        },
        {
          label: "Type charge",
          page: "/admin/charge_types",
          icon: "pi-percentage",
          visible: userHasPermission("CAN_ACCESS_ADMIN_CHARGE_TYPE")
        },
        {
          label: "Commissions produits banque",
          page: "/admin/bank_product_commissions",
          icon: "pi-dollar",
          visible: userHasPermission("CAN_ACCESS_ADMIN_COMMISSION")
        },
        {
          label: "Produits banques",
          page: "/admin/bank_products",
          icon: "pi-wallet",
          visible: userHasPermission("CAN_ACCESS_ADMIN_BANK_PRODUCT")
        },
        {
          label: "Capacités juridiques",
          page: "/admin/legal_capacities",
          icon: "pi-id-card",
          visible: userHasPermission("CAN_ACCESS_ADMIN_LEGAL_CAPACITY")
        },
        {
          label: "Types assurances",
          page: "/admin/insurance_types",
          icon: "pi-folder",
          visible: userHasPermission("CAN_ACCESS_ADMIN_INSURANCE_TYPE")
        },
        {
          label: "Contact positions",
          page: "/admin/contact_positions",
          icon: "pi-user-edit",
          visible: userHasPermission("CAN_ACCESS_ADMIN_CONTACT_POSITION")
        },
        {
          label: "Types evenements contact",
          page: "/admin/contact_event_types",
          icon: "pi-users",
          visible: userHasPermission("CAN_ACCESS_ADMIN_CONTACT_EVENT_CONTACT_EVENT_TYPE")
        },
        {
          label: "Objectif de la trésorerie",
          page: "/admin/cash_requirement_goal",
          icon: "pi-euro",
          visible: userHasPermission("CAN_ACCESS_ADMIN_CASH_REQUIREMENT_GOAL")
        },
        {
          label: "Banques",
          page: "/admin/banks",
          icon: "pi-money-bill",
          visible: userHasPermission("CAN_ACCESS_ADMIN_BANK")
        },
        {
          label: "Marques",
          page: "/admin/brands",
          icon: "pi-bookmark",
          visible: userHasPermission("CAN_ACCESS_ADMIN_BRAND")
        },
        {
          label: "Positions",
          page: "/admin/positions",
          icon: "pi-map-marker",
          visible: userHasPermission("CAN_ACCESS_ADMIN_POSITION")
        }
      ]
    }
  ];
});

function goTo (item, external = false) {
  if (item.page && !item.disabled) {
    navigateTo(item.page, { external });
  }
}
</script>
